/*
  *首页样式
  bill_yang 2021.09
*/
@import '../index.scss';

.personal {
  height: 100vh;
  overflow: hidden;
  background-image: url(../../imgs/webp/white-back.webp);
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;

  .personal-content {
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    display: flex;
    padding-top: vw(103);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    z-index: 10;

    .personal-information {
      width: vw(316);
      height: vw(800);
      backdrop-filter: blur(40px);
      border-top-left-radius: vw(16);
      border-bottom-left-radius: vw(16);
      background-color: #FFF;
      box-shadow: 7px 0px 32px rgba(192, 205, 214, 0.4);
      color: rgba(13, 17, 35, 0.48);;
      z-index: 100;
      .personal-back{
        width: vw(284);
        height: vw(220);
        border-radius: vw(8);
        margin: vw(16) vw(16) vw(40);
        padding: vw(32) 0 0;
        background-color: #EFF6FA;
        position: relative;
        .edit-icon{
          width: 32px;
          height: 32px;
          background-color: #fff;
          position: absolute;
          top: vw(16);
          right: vw(16);
          cursor: pointer;
          background: url('../../imgs/svg/user-edit.svg') no-repeat;
          background-position: -8px -8px;
          &:hover{
          background-position: -56px -8px;
          }
        }
      }

      .user-picture {
        width: vw(88);
        height: vw(88);
        margin: vw(0) auto vw(16) ;
        >img {
          border-radius: 50%;
        }
      }

      .user-text {
        // width: vw(252);
        height: vw(52);
        margin-top: vw(16);
        position: relative;

        .user-name div:nth-child(1),
        .long-user-name div:nth-child(1){
          width: vw(96);
          height: vw(29);
          line-height: vw(29);
          font-size: vw(24);
          font-weight: 700;
          color: #07080A;
          margin: 0 auto;
          text-align: center;
        }
        .user-name div:nth-child(2),
        .long-user-name div:nth-child(2){
          width: vw(244);
          height: vw(19);
          line-height: vw(19);
          font-size: vw(16);
          font-weight: 700;
          color: #07080A;
          margin: vw(4) auto 0;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .long-user-name div:nth-child(2)::before,
        .user-name div:nth-child(2)::before{
          content: '';
          display: block;
          width: vw(24);
          height: vw(24);
          background-image: url(../../imgs/svg/huan.svg);
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          right: vw(76);
          top: vw(-10);
        }


        p {
          border-radius: vw(8);
          border: vw(2) solid #fff;
          padding: vw(4) 0;
          width: vw(102);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: vw(18);
          margin: vw(16) auto 0 auto;
          cursor: pointer;

          // span {
          //   // margin-left: vw(8);
          // }

          &.active {
            background-color: #fff;
            color: #000;
            font-weight: 700;
          }

          &:hover {
            background-color: #fff;
            color: #000;
            font-weight: 700;
          }
        }
      }

      .personal-nav {

        ul {
          li {
            margin-top: vw(40);
            display: flex;
            justify-content: center;
            height: vw(38);
            line-height: vw(38);

            div {
              // padding: 0 vw(16);
              width: vw(284);
              height: vw(54);
              line-height: vw(54);
              font-size: vw(18);
              color: rgba(13, 17, 35, 0.48);;
              text-align: center;
              margin: 0 auto;
              cursor: pointer;
              border-radius: vw(8);

              &.active {
                background-color: #FFF;
                color: #07080A;
              }

              &:hover {
                background-color: #EFF6FA;
                color: #000;

              }
            }
          }
        }
      }
    }
  }

  .personal-main {
    width: vw(984);
    height: vw(800);
    // padding: 0 vw(40);
    background-color: #fff;
    border-top-right-radius: vw(16);
    border-bottom-right-radius: vw(16);
    position: relative;

    // 订单
    .flights-list {
      margin-left: vw(40);
      overflow: hidden;

      .ant-tabs {
        width: vw(904);
        height: vw(52);
        margin-top: vw(40);
        border-bottom:1px solid  rgba(7, 19, 33, 0.08);

        .ant-tabs-nav {
          &::before {
            display: none;
          }

          .ant-tabs-nav-wrap {
            display: flex;
            justify-content: center;

            .ant-tabs-tab {
              color: rgba(13, 17, 35, 0.48);;
              font-size: vw(18);
              font-weight: 700;
              padding: 0 0 vw(4) 0;

              &+.ant-tabs-tab {
                margin: 0 0 0 vw(40);
              }
            }

            .ant-tabs-ink-bar {
              background-color: #0063DB;
            }

            .ant-tabs-tab-active .ant-tabs-tab-btn {
              color: #0063DB;
              font-weight: 700;
              text-shadow: none;
            }
          }
        }
      }

      >.list {
        height: vw(645);
        overflow-y: auto;
        color: #07080A;
        // padding: 0 vw(40) 0 0;
        margin-top: vw(24);
        // border-top: vw(16) solid transparent;
        // border-bottom: vw(16) solid transparent;

        &.list::-webkit-scrollbar {
          display: none
        }

        >ul {
          height: 100%;

          >li {
            width: vw(904);
            height: vw(102);
            background-color: #DFE7F5;
            // padding: vw(16) vw(24);
            border-radius: vw(16);
            cursor: pointer;
            .right-none{
              width: vw(56);
              height: vw(102);
              background-color: #0063DB;
              border-top-right-radius: vw(16);
              border-bottom-right-radius: vw(16);
              display: none;
              .border-people{
                width: vw(56);
                border-bottom: 1px solid rgba(7, 19, 33, 0.08);
                cursor: pointer;
              }
              div{
                width: vw(56);
                text-align: center;
              }
              img{
                display: inline-block;
                width: vw(24);
                height: vw(24);
                margin-top: vw(14);
                margin-bottom: vw(13);
              }
              .right-white{
                margin-top: vw(25);
              }
            }
            .left{
              padding: vw(16) vw(24);
            }
            &+li {
              margin-top: vw(16);
            }
            &:hover{
              i {
                font-style: normal;
              }
              display: flex;
              justify-content: space-between;
              .plane-type{
                width: vw(52);
              }
              .left{
                width: vw(848);
              }
              .right-none{
                display: block;
                z-index: 100;
                &:hover{
                  background-color: #1264C7;
                }
              }
            }

            .time {
              position: relative;
              display: flex;
              justify-content: space-between;
              .order-type{
                // width: vw(221);
                height: vw(19);
                font-size: vw(16);
                font-weight: 700;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .popular{
                  // width: vw(119);
                  color: #0063DB;
                }
                .one-way{
                  width: vw(85);
                  text-align: left;
                  color: rgba(13, 17, 35, 0.48);
                }
                .shuxian{
                  width: vw(1);
                  height: vw(19);
                  border: 1px solid rgba(0, 0, 0, 0.2);
                  margin: 0 vw(16);
                }
                
              }

              >span {
                font-size: vw(16);
                font-weight: 700;

                &.pending {
                  color: #17C7FF;
                }

                &.upcoming {
                  color: #2DCA73;
                }

                &.finished {
                  color: #F7C102;
                }

                &.cancelled {
                  color: rgba(13, 17, 35, 0.48);
                }

                &.refunding {
                  color: #FF8E65;
                }
              }

              >p {
                font-size: vw(18);
                color: rgba(255, 255, 255, 0.5);
              }
            }

            .form-to {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: vw(18);
              font-weight: 700;
              .lt-time{
                font-size: vw(16) !important;
                line-height: vw(19) !important;
                margin-left: vw(8) !important;
                font-weight: 400 !important;
              }
              i {
                font-style: normal;
              }
              .from{
                div{
                  display: flex;
                  align-items: center;
                  width: vw(358);
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  i{
                    display: inline-block;
                    width: vw(24);
                    height: vw(24);
                    margin-right: vw(8);
                    background-image: url(../../imgs/svg/search-from.svg);
                  }
                }
              }
              .to{
                div{
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  width: vw(358);
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  i{
                    display: inline-block;
                    width: vw(24);
                    height: vw(24);
                    margin-right: vw(8);
                    background-image: url(../../imgs/svg/search-to.svg);
                  }
                }
              }
              .plane-type{
                width: vw(52);
              }
            }
            .country{
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .country-search{
              display: flex;
              justify-content: space-between;
              align-items: center;
              div{
                &:first-child,&:last-child{
                  width: vw(358);
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                &:last-child{
                  text-align: right;
                }
              }
            }

            .passenger {
              padding: vw(6) 0;
              font-size: vw(18);
              font-weight: 700;
              text-align: center;
              background: $bgck-airtaxi;
              box-shadow: vw(20) 0 vw(70) rgba(6, 9, 20, 0.59);
              border-radius: vw(8);
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              margin: vw(8) 0 0;

              &::before {
                content: "";
                display: block;
                width: vw(24);
                height: vw(24);
                background-image: url(../../imgs/svg/add.svg);
                background-repeat: no-repeat;
                background-size: contain;
              }
            }

          }
        }
      }
    }

    // 个人信息
    .personal-data {
      color: #07080A;
      font-size: vw(18);
      margin-left: vw(40);
      margin-top: vw(40);
      .edit{
        width: vw(904);
        height: vw(46);
        font-size: vw(18);
        font-weight: 700;
        border-bottom: vw(1) solid rgba(7, 19, 33, 0.08);
        margin-bottom: vw(24);
      }


      >ul {
        overflow: hidden;

        p {
          margin-bottom: vw(8);
        }

        li {
          width: vw(530);
          height: vw(54);
          margin-bottom: vw(24);
          padding: 0 vw(24);
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: vw(16);
        }
      }


      .public-form {

        width: vw(904);

        .public-form-box {
          // width: vw(530);
          .two-box{
            display: flex;
            .left{
              width: vw(444);
            }
            .right{
              width: vw(444);
              margin-left: vw(16);
            }
          }
        }

        .ant-select-selector {
          width: vw(200);
          height: vw(54);
          border-radius: vw(16);
          border: vw(2) solid rgba(255, 255, 255, 0.2);
          background-color: transparent;
          padding-left: vw(40);
          position: relative;

          &::before {
            content: '+';
            display: block;
            color: #fff;
            position: absolute;
            left: vw(24);
            top: 50%;
            font-size: vw(18);
            margin-top: vw(-14);
          }

          .ant-select-selection-search-input {
            padding-left: vw(24);
          }

          .ant-select-selection-item,
          .ant-select-selection-search-input,
          .ant-select-selection-placeholder {
            color: #fff;
            height: vw(54);
            line-height: vw(54);
            font-size: vw(18);
          }
        }

        .ant-select-arrow {
          width: vw(20);
          height: vw(20);
          top: 50%;
          margin-top: vw(-10);
        }

        .anticon-search,
        .anticon-down {
          font-size: vw(18);
          color: rgba(255, 255, 255, 0.8);
        }

        .ant-input-affix-wrapper {
          height: vw(51);
          border-radius: vw(8);
          // border: vw(2) solid rgba(255, 255, 255, 0.2);
          border: 1.6px solid rgba(0, 0, 0, 0.2);
          background-color: transparent;
          padding-left: vw(16);

          .ant-input-prefix {
            width: vw(32);
            margin-right: vw(16);
            font-size: vw(20);

            .anticon-mail {
              color: $bgck-light-blue;
            }

            .anticon-user {
              color: $bgck-light-orange;
            }

            .anticon-phone {
              color: $bgck-light-yellow;
              transform: rotate(90deg);
            }

            .anticon-lock {
              color: $bgck-light-green;
            }
          }

          .ant-input-suffix {
            width: vw(24);
            font-size: vw(20);
            margin: 0;

            .anticon-close-circle {
              font-size: vw(18);
              margin: 0;
            }

            .anticon {
              color:rgba(0, 0, 0, 0.2);;
            }
          }

          .ant-input {
            background-color: transparent;
            color: #07080A;
            font-size: vw(16);
          }
        }

        .ant-select-selection-placeholder {
          color: rgba(255, 255, 255, 0.2) !important;
        }

        .ant-form-item-has-error .ant-input,
        .ant-form-item-has-error .ant-input-affix-wrapper,
        .ant-form-item-has-error .ant-input:hover,
        .ant-form-item-has-error .ant-input-affix-wrapper:hover {
          border-color: $bgck-light-red;
          box-shadow: none;
        }

        .ant-btn {
          width: vw(350);
          height: vw(61);
          border-radius: vw(8);
          font-size: vw(24);
          font-weight: 700;
          background-image: url(../../imgs/svg/add-btn-bg.svg);
          background-repeat: no-repeat;
          background-size: cover;
          border: none;
          outline: none;
          // margin-top: vw(380);
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-child(2){
            &:hover{
              box-shadow: 0px 4px 16px rgba(72, 84, 115, 0.4);
            }
          }


          i {
            width: vw(40);
            height: vw(40);
            background-image: url(../../imgs/svg/suo.svg);
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: vw(8);
          }
        }

        .two-item {
          display: flex;
          width: 100%;
          .ant-form-item {
            flex: 1;

            &+.ant-form-item {
              margin-left: vw(24);
            }
          }

          .full-width {
            width: vw(444);
            height: vw(51);
            display: flex;
            border: vw(1.6) solid rgba(0, 0, 0, 0.2);
            border-radius: vw(8);
            &:hover{
              border-color: #07080A;
            }
            .left-flag{
              display: flex;
              align-items: center;
              // margin-top: vw(5);
              img{
                width: vw(30);
                height: vw(20);
                margin-left: vw(16);
              }
              .down-sanjiao{
                width: vw(15);
                height: vw(15);
                background: url(../../imgs/svg/black-down-sanjiao.svg) no-repeat;
                margin: 0 vw(16) 0 vw(8);
              }
              div{
                font-size: vw(16);
              }
            }
            .ant-form-item {
              width: 100%;
              .ant-input-affix-wrapper{
                height: vw(51);
                // padding-left: vw(100) !important;
                border: none !important;
              }
            }
            .ant-form-item-explain-error{
              margin-left: vw(-104);
            }
            .ant-form-item-explain-success{
              margin-left: vw(-104);
            }
            .ant-input-affix-wrapper .ant-input{
              margin-bottom: vw(3);
            }
          }
          .err-border{
            border-color: red;
          }
        }

        .form-title {
          font-size: vw(16);
          font-weight: 700;
          color: #07080A;
          margin-bottom: vw(4);
        }

        .cancel {
          // background: transparent;
          width: vw(350);
          height: vw(61);
          line-height: vw(61);
          font-size: vw(24);
          font-weight: 700;
          // margin-top: vw(380);
          border-radius: vw(8);
          color: rgba(13, 17, 35, 0.48);
          border: vw(2) solid rgba(0, 0, 0, 0.2);
          text-align: center;
          cursor: pointer;

        }
      }

      .public-form-profile {
        width: vw(904);

        .ant-form-item-control-input-content {
          display: flex;

          .cancel {
            margin-right: vw(24);
          }
        }
      }

    }

   // 会员卡
   .member-card{
    margin-left: vw(40);
    margin-top: vw(40);
    margin-right: vw(40);
    height: vw(720);
    position: relative;
    .card-title{
      font-size: vw(18);
      line-height: vw(22);
      font-weight: 700;
    }
    .xian{
      width: vw(904);
      height: vw(1);
      background-color: rgba(7, 19, 33, 0.08);
      margin-top: vw(24);
    }
    .cards{
      display: flex;
      flex-wrap: wrap;
    }
    .card-type{
      width: vw(400);
      height: vw((224));
      border-radius: vw(8);
      padding: vw(24) vw(16);
      color: #fff;
      margin-right: vw(24);
      margin-top: vw(24);
      .card-name{
        display: flex;
        align-items: center;
        font-size: vw(20);
        font-weight: 700;
        line-height: vw(24);
        margin-bottom: vw(8);
        >i{
            display: block;
            width: vw(24);
            height: vw(24);
            background-image: url('../../imgs/svg/member-icon-white.svg');
            margin-right: vw(8);
        }
      }
      .card-state{
        padding: vw(4) vw(8);
        border-radius: vw(4);
        background-color: rgba(7, 19, 33, 0.08);
        font-size: vw(18);
        font-weight: 700;
      }
      .card-time{
        margin-top: vw(66);
        font-size: vw(18);
        .card-time-more{
          display: flex;
          justify-content: space-between;
          margin-top: vw(4);
          span{
            &:first-child{
              font-weight: 700;
            }
            &:last-child{
              text-decoration: underline;
              cursor: pointer;
              transition: All 0.15s ease-out;
              &:hover{
                  transform: translate(0, -5px);
              }
            }
          }
        }
      }
    }
    .options-btn{
      position: absolute;
      bottom: 0;
      width: vw(350);
      height: vw(61);
      background-image: url(../../imgs/svg/add-btn-bg.svg);
      cursor: pointer;
      border: none;
      color: #fff;
      font-size: vw(20);
      line-height: vw(24);
      font-weight: 700;
      border-radius: vw(8);
      &:hover{
        box-shadow: 0px 4px 16px rgb(72 84 115 / 40%);
        background-size: cover;
      }
    }
    .Trial{
      background-image: url('../../imgs/svg/card-trial.svg');
      color:#000;
      opacity: 0.75;
    }
    .Gold{
      background-image: url('../../imgs/svg/card-gold.svg');
    }
    .Platinum{
      background-image: url('../../imgs/svg/card-platinum.svg');
    }
    .back{
      display: block;
      width: vw(40);
      height: vw(40);
      cursor: pointer;
      margin-bottom: vw(16);
      background-image: url(../../imgs/svg/back_jump.svg);
      &:hover{
        background-image: url(../../imgs/svg/back_jump_hover.svg);
      }
    }
    .card-information{
      width: vw(904);
      height: vw(92);
      border-radius: vw(8);
      background-color: #EFF6FA;
      padding: vw(16) vw(24);
      margin-bottom: vw(16);
      >div{
        display: flex;
        justify-content: space-between;
        margin-bottom: vw(16);
      }
      b{
        margin-left: vw(8);
      }
    }
    .permissions-title{
      font-size: vw(18);
      line-height: vw(22);
      font-weight: 700;
      margin-bottom: vw(16);
    }
    .permissions-content{
      width: vw(904);
      height: vw(443);
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none
      }
      li{
        font-size: vw(16);
        line-height: vw(32);
      }
    }
    .cancel-back{
      font-size: vw(18);
      line-height: vw(22);
      font-weight: 700;
      text-decoration: underline;
      position: absolute;
      bottom: 0;
      cursor: pointer;
      transition: All 0.15s ease-out;
      &:hover{
          transform: translate(0, -5px);
      }
    }
    .active-member{
      height: vw(648);
      .no-box{
        margin-top: vw(96);
        height: auto;
        .no-content{
          width: vw(315);
        }
        p{
          font-size: vw(18);
          line-height: vw(22);
          font-weight: 700;
          text-align: center;
        }
      }
      .add-member{
        width: vw(350);
        height: vw(61);
        background-image: url('../../imgs/svg/add-btn-bg.svg');
        cursor: pointer;
        border-radius: vw(8);
        border: none;
        color: #fff;
        font-size: vw(24);
        font-weight: 700;
        position: absolute;
        bottom: vw(0);
        left: 0;
        &:hover{
          box-shadow: 0px 4px 16px rgb(72 84 115 / 40%);
          background-size: cover;
        }
      }
    }
  }
      
  
    // 优惠卷
    .coupons {
      // margin-left: vw(130);
      // width: vw(760);
      margin-left: vw(40);
      .coupons-title {
        margin: vw(40) vw(40) 0 0;
        height: vw(46);
        line-height: vw(22);
        font-size: vw(18);
        font-weight: 700;
        color: #07080A;
        border-bottom: 1px solid rgba(7, 19, 33, 0.08);;
      }
      .coupons-items{
        width: vw(904);
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap-reverse
      }
    }
  }
}

.profile-coupons-list {
  width: vw(904);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // height: vw(701);

  &::-webkit-scrollbar {
    display: none
  }

}

// 乘客
.passenger {
  margin-left: vw(40);
  margin-top: vw(40);
  color: #07080A;
  width: 100%;

  input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.2);

  }

  input::-moz-input-placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
  input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
  .add-passenger-icon {
    width: vw(40);
    height: vw(40);
    background-image: url(../../imgs/svg/addck_hover.svg);
    margin-right: vw(4);
  }

  .passenger-order {
    background: rgba(7, 19, 33, 0.08);
    border-radius: 8px;
    margin-bottom: vw(24);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .shuxian{
      width: vw(1);
      height: vw(120);
      border-left: 1px solid rgba(7, 19, 33, 0.08);
    }
    .passenger-order-left{
      width: vw(840);
      height: vw(109);
      margin-left:vw(16);
      >p {
        height: vw(22);
        line-height: vw(22);
        font-size: vw(18);
        font-weight: 700;
        text-align: center;
        margin-bottom: vw(16);
      }
    }
    .passenger-order-right{
      width: vw(348);
      height: vw(120);
      margin: vw(16) vw(16) vw(16) 0;
    }
    



    .address {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .address-time{
        height: vw(15);
        line-height: vw(15);
        font-size: vw(12);
        color: #07080A;
        span{
          &:nth-child(1),&:nth-child(3){
            width: vw(386);
          }
          &:nth-child(3){
           text-align: right;
          }
        }
      }
      .address-city{
        font-size: vw(18);
        font-weight: 700;
        color: #07080A;
        span{
          &:nth-child(1), &:nth-child(3){
            width: vw(386);
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &:nth-child(3){
            text-align: right;
          }
        }
        img{
          width: vw(56);
          height: vw(16);
        }
      }
      .address-airport,.address-icao{
        font-size: vw(12);
        color: rgba(13, 17, 35, 0.48);
        line-height: vw(14);
      }
  
    }

    .configuration {
      margin-top: vw(8);
      position: relative;


      .bgm-tips{
        width: vw(24);
        height: vw(24);
        background: url(../../imgs/svg/remind.svg) no-repeat -20px -20px;
        position:absolute;
        top: vw(-1);
        left: vw(94);
        cursor: pointer;
        z-index: 100;
        &:after{
          display: none;
          content: "";
          width: 0;
          height: 0;
          border: vw(8) solid transparent;
          border-top-color: #fff;
          position: absolute;
          top: vw(-10);
          left: vw(4);
        }
        &:hover{
           background: url(../../imgs/svg/remind.svg) no-repeat -68px -20px;
           p{
            display: block;
           }
           &:after{
            display: block;
           }
        }
        p{
          display: none;
          width: vw(303);
          height: vw(72);
          font-size: vw(12);
          padding: vw(8) 0 vw(8) vw(25);
          border-radius: vw(8);
          background-color: #fff;
          color: #07080A;
          position: absolute;
          top: vw(-82);
          left: vw(-100);
          filter: drop-shadow(0px 4px 56px rgba(77, 100, 126, 0.25));
        }
      }

      p {
        display: flex;
        align-items: center;
        margin-bottom: vw(16);
        // flex: 1;

        >i {
          width: vw(40);
          height: vw(40);

          &.plane {
            background-image: url(../../imgs/svg/black-plane.svg);
          }


          &.seats {
            background-image: url(../../imgs/svg/black-seat.svg);
          }
        }

        span {
          margin-left: vw(4);

          i {
            display: block;
            font-style: normal;
            font-size: vw(14);

            &:first-child {
              color: #07080A;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
  
  .passenger-title {
    // width: vw(904);
    margin-right:vw(40) ;
    height: vw(46);
    line-height: vw(22);
    border-bottom: 1px solid rgba(7, 19, 33, 0.08);;
    display: flex;
    justify-content: space-between;
    font-size: vw(18);
    font-weight: 700;
    position: relative;

    >span {
      i {
        display: inline;
        font-size: vw(18);
        color: #07080A;
        font-style: normal;
        margin-left: vw(8);
      }

      // &:last-child {
      //   cursor: pointer;
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      //   padding: vw(4) vw(12);
      //   border-radius: vw(8);
      //   margin-right: vw(-12);

      //   &:hover {
      //     background-color: rgba(255, 255, 255, 0.2);
      //   }
      // }
    }

    .add-passenger {
      width: vw(210);
      height: vw(48);
      background-color: rgba(7,19,33,0.08);
      font-size: vw(18);
      font-weight: 700;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: vw(8);

      span {
        font-size: vw(24);
        margin-right: vw(8);
      }

      &:hover {
        background-color: #07080A;
        color: #fff;

        .add-passenger-icon {
          background-image: url(../../imgs/svg/addck.svg);
        }
      }
    }
  }

  .passenger-list {
    padding: vw(24) vw(40) 0 0;

    .passenger-list-ul {
      height: vw(544);
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none
      }
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &+li {
        margin-top: vw(16);
      }

      .passengers-text {
        flex: 1;
        height: vw(40);
        display: flex;
        align-items: center;
        color: #07080A;
        font-size: vw(18);
        background-color: #DFE7F5;
        border-radius: vw(8);
        cursor: pointer;
        &:hover{
          background-color: #C6D3EB;
        }

        i {
          width: vw(24);
          height: vw(24);
          background-image: url(../../imgs/svg/passenger.svg);
          margin-left: vw(18);
        }

        p {
          flex: 1;
          color: #0063DB;
          text-align: center;
        }

        span {
          width: vw(93);
          text-align: center;
          position: relative;

          &::before {
            content: '';
            display: block;
            width: vw(1);
            height: vw(12);
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: vw(-6);
          }
        }
      }

      .delete {
        width: vw(24);
        height: vw(24);
        font-size: vw(20);
        display: flex;
        align-items: center;
        margin-left: vw(24);
        cursor: pointer;
      }

      .choose {
        width: vw(24);
        height: vw(24);
        border: vw(2) solid rgba(0, 0, 0, 0.2);
        border-radius: vw(4);
        margin-left: vw(16);
        cursor: pointer;

        &.active {
          border: none;
          background-image: url(../../imgs/svg/selected.svg);
        }
      }
    }
  }

  .passenger-form {
    width: 100%;
    padding-right: vw(40);

    .passenger-back-icon {
      position: relative;
      height: vw(40);
      line-height: vw(38);
      margin-bottom: vw(24);


      .back-jump {
        width: vw(40);
        height: vw(40);
        background-image: url(../../imgs/svg/left-back.svg);
        position: absolute;
        left: vw(0);
        top: vw(0);
        cursor: pointer;

        &:hover {
          background-image: url(../../imgs/svg/back_jump_hover.svg);
        }
      }

      >p {
        font-weight: 700;
        margin-left: vw(64);
        font-size: vw(18);
      }
    }

    >p {
      font-size: vw(18);
      font-weight: 700;
      margin-bottom: vw(24);
    }

    .ant-picker-suffix,
    .ant-picker-clear {
      display: none;
    }

    .ant-form-item-control-input{
      min-height: vw(24) !important;
    }

    .ant-input-affix-wrapper,
    .ant-input-number,
    .ant-picker {
      width: vw(444);
      height: vw(51);
      line-height: vw(51);
      border-radius: vw(8);
      // border: vw(2) solid rgba(255, 255, 255, 0.2) !important;
      border: vw(1.6) solid rgba(0, 0, 0, 0.2) !important;
      background-color: transparent;
      padding-left: vw(16);
      // box-shadow: none !important;
      // border-right-width: vw(2) !important;

      input {
        display: flex;
        align-items: center;
      }

      &:hover {
        border-color: #07080A !important;
        background-color: transparent;
      }

      &:focus {
        border-color: #07080A !important;
      }

      .ant-input-suffix {
        width: vw(24);
        font-size: vw(20);
        margin: 0;

        .anticon-close-circle {
          font-size: vw(18);
          margin: 0;
        }

        .anticon {
          color: rgba(0, 0, 0, 0.2);
          ;
        }
      }

      .ant-input-number-handler-wrap {
        display: none;
      }

      .ant-input-number-input {
        height: vw(52);
        color: #07080A;
        font-size: vw(18);
        padding: 0;
      }

      .ant-input,
      #basic_birthday {
        background-color: transparent;
        color: #07080A;
        font-size: vw(18);
      }
    }

    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-picker,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
      border-color: $bgck-light-red  !important;
    }
    .ant-btn-primary{
      &:hover {
        background: url(../../imgs/svg/save-btn.svg) no-repeat;
        box-shadow: 0px 4px 16px rgba(72, 84, 115, 0.4);
        background-size: cover;
        color: #fff;
      }        
    }
    .ant-btn {
      width: vw(350);
      height: vw(61);
      border-radius: vw(8);
      font-size: vw(24);
      font-weight: 700;
      background-image: url(../../imgs/svg/save-btn.svg);
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      outline: none;
      // margin-top: vw(24);
      display: flex;
      justify-content: center;
      align-items: center;

      &+.ant-btn {
        margin-left: vw(24);
      }

      &.cancel {
        background: transparent;
        color: rgba(13, 17, 35, 0.48);;
        border: 2px solid rgba(0, 0, 0, 0.2);
        filter: drop-shadow(11px 11px 70px rgba(0, 0, 0, 0.25));
      }
    }

    .two-item {
      display: flex;
      width: 100%;
      .bottom-box{
        position: absolute;
        top: vw(198);
        left: 0;
      }
      .public-box {
        flex: 1;
        position: relative;

        &+.public-box {
          margin-left: vw(16);
        }

        .ant-form-item-control-input-content {
          display: flex;
        }

        .year-month {
          width: vw(300);
          height: vw(200);
          background-color: #fff;
          border-radius: vw(16);
          position: absolute;
          right: 0;
          bottom: vw(-190);
          z-index: 99;
          padding: vw(8);
          display: flex;

          ul {
            flex: 1;
            text-align: center;
            height: 100%;
            overflow-y: auto;

            li {
              padding: vw(4) 0;
              cursor: pointer;

              &:hover {
                color: $bgck-airtaxi;
              }
            }
          }
        }
      }
    }

    .form-title {
      height: vw(19);
      line-height: vw(19);
      font-size: vw(16);
      font-weight: 700;
      color: #07080A;
      margin-bottom: vw(8);
    }

    .ant-radio-group {
      display: flex;
    }

    .ant-radio-wrapper {
      margin-right: vw(32);
      display: flex;
      align-items: center;

      >span {
        &:last-child {
          color: #07080A;
          font-size: vw(18);
          height: vw(24);
        }
      }

      .ant-radio {
        width: vw(24);
        height: vw(24);
        border-radius: vw(4);
        border: vw(2) solid rgba(0, 0, 0, 0.2);

        .ant-radio-inner {
          background-color: transparent;
          border: none;
        }

        .ant-radio-input {
          opacity: 0;
          width: 100%;
          height: 100%;
        }

        .ant-radio-inner::after {
          display: none;
        }

        &.ant-radio {
          top: vw(.1);
        }

        &.ant-radio-checked {
          background-image: url(../../imgs/svg/selected.svg);
          background-repeat: no-repeat;
          background-size: contain;
          border: none;

          &::after {
            display: none;
          }

        }
      }
    }

  }
}

.delete-add{
  display: flex;
  position: absolute;
  bottom: vw(40);
  left:vw(40);
  div{
    &:nth-child(1){
      width: vw(350);
      height: vw(61);
      border: 2px solid rgba(0, 0, 0, 0.2);
      filter: drop-shadow(11px 11px 70px rgba(0, 0, 0, 0.25));
      border-radius: vw(8);
      font-style: normal;
      font-weight: 700;
      font-size: vw(24);
      line-height: vw(57);
      text-align: center;
      color: rgba(13, 17, 35, 0.48);
      cursor: pointer;
      margin-right: vw(24);
    }
  }

}
.confirm-sub{
  position: absolute;
  bottom: vw(24);
  left:50%;
  transform: translate(-50%,0);
  // margin: vw(24) auto 0;
}
.submit {
  width: vw(350);
  height: vw(61);
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: vw(24);
  font-weight: 700;
  background-image: url(../../imgs/svg/add-btn-bg.svg);
  border-radius: vw(8);
  // margin-top: vw(48);
  // position: absolute;
  // bottom: vw(40);
  // left:vw(40);
  cursor: pointer;
  border: none;
  color: #fff;

  &.add-per {
    // margin: 0 auto !important;
    position: absolute;
    bottom: vw(-252);
    left: vw(0);
  }

  &:hover {
    background: url(../../imgs/svg/add-btn-bg.svg) no-repeat;
    box-shadow: 0px 4px 16px rgba(72, 84, 115, 0.4);
    background-size: cover;
    color: #fff;
  }

  &.active {
    margin: vw(24) auto 0 auto;
  }

  span {
    margin-left: vw(8);
  }
}

.profile-passenger {
  width: vw(944);
}

.flights-passenger {
  padding-top: vw(160);
  background-color: #fff;
  min-height: 100vh;

  .flights-passenger-width {
    position: relative;
    width: vw(1300);
    height:100%;
    margin: 0 auto;
    background-color: #EFF6FA;
    padding: vw(24);
    border-radius: vw(16);
    .back-jump-top{
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      margin-left: vw(-650);
      top: vw(-56);
    }
    .back-jump {
      width: vw(40);
      height: vw(40);
      background-image: url(../../imgs/svg/back_jump.svg);
      cursor: pointer;

      &:hover {
        background-image: url(../../imgs/svg/back_jump_hover.svg);
      }
    }
    .top-type{
      display: flex;
      align-items: center;
      margin-left: vw(16);
      font-style: normal;
      div{
        &:nth-child(1){
          width: vw(150);
          height: vw(22);
          line-height: vw(22);
          font-size: vw(18);
          font-weight: 700;
          color: #07080A;
          border-right: vw(2) solid rgba(0, 0, 0, 0.2);
        }
        &:nth-child(2){
          width: vw(96);
          height: vw(22);
          line-height: vw(22);
          font-size: vw(18);
          font-weight: 700;
          color: rgba(13, 17, 35, 0.48);
          margin-left: vw(16);
        }
      }
    }

    .passenger {
      margin: 0;
      .passenger-form{
        background: #fff;
        padding: vw(24) vw(24) 0 vw(24);
        margin-bottom: vw(100);
        border-radius: vw(8);

        .bottom-box{
          position: absolute;
          top: vw(40) !important;
          left: vw(-24) !important;
        }
        .passenger-back-icon{
          display: none;
        }
        .ant-input-affix-wrapper,.ant-picker{
          width: vw(594);
        }
      }
    }
    .passenger-title {
      width: 100%;
      border: transparent;
      line-height: vw(46);
      margin-bottom: vw(16);

    }
    .passenger-list{
      // position: relative;
      background-color: #fff;
      padding: vw(16);
      height: vw(328);
      overflow: auto;
      margin-bottom: vw(85);
      border-radius: vw(8);
      &::-webkit-scrollbar{
        display: none;
      }
    }
    .no-box{
      width: 100%;
      height: vw(278);
      background-color: #fff;
      border-radius: vw(8);
      margin-top: 0 !important;
      padding: vw(32) 0 ;
      p{
        font-size: vw(18);
        margin: vw(24) 0 0 0;
      }
    }
  }

  .clause {
    margin-top: vw(80);
  }
}

.personal .personal-main .flights-list .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: start;
}

.listo {
  height: vw(645);
  overflow-y: auto;
  color: #fff;
  // border-radius: vw(16);
  margin-top: vw(24);
  // border-top: vw(16) solid transparent;
  // border-bottom: vw(16) solid transparent;

  &.listo::-webkit-scrollbar {
    display: none
  }

  ul {
    width: 100%;
    height: 100%;

    >li {
      width: vw(904);
      cursor: pointer;
      background: #DFE7F5;
      border-radius: vw(16);
      overflow: hidden;
      // padding: vw(16);
      margin-bottom: vw(16);
      display: flex;
      .li-left{
        width: vw(904);
        padding: vw(16);
      }
      .li-right{
        width: vw(56);
        height: vw(118);
        background-color: #0063DB;
        // border-top-right-radius: vw(8);
        img{
          display: inline-block;
          width: vw(24);
          height: vw(24);
          margin: vw(47) 0 0 vw(16);
        }
        &:hover{
          background-color: #1264C7;
        }
      }
      // &:last-child{
      //   margin-bottom: 0;
      // }
      &:hover{
        .li-left{
          width: vw(848);
        }
      }
    
      .Submission {
        margin-top: vw(16);
        
        >span {
          font-weight: 400;
          font-size: vw(18);
          line-height: vw(22);
          color: rgba(255, 255, 255, 0.5);

          b {
            font-weight: 700;
            color: #FFFFFF;
            margin-left: vw(4);
          }
        }
      }
    
      .quote {
        font-weight: 700;
        font-size: vw(16);
        color: #0063DB;
        line-height: vw(19);
        text-align: center;
      }
    
      .Request {
        font-weight: 700;
        font-size: vw(16);
        line-height: vw(19);
        color: #FF8E65;
      }
    
      .Quote {
        color: #2DCA73;
        font-weight: 700;
        font-size: vw(18);
        line-height: vw(22);
      }
      
      .quotemain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: vw(19);
        .quo-left{
          height: vw(19);
          span{
            &:nth-child(2){
              margin-left: vw(24);
            }           
          }
        }
        .quo-right{
          span{
            &:nth-child(1){
              color:  rgba(13, 17, 35, 0.48);
            }
            &:nth-child(2),&:nth-child(4){
              font-weight: 700;
              color:  #07080A;
            }
            &:nth-child(3){
              color:  rgba(13, 17, 35, 0.48);
              margin-left: vw(40);
            } 
          }
        }
      }
    
      .destination {
        // width: vw(872);
        // width: 100%;
        height: vw(59);
        background: #FFF;
        border-radius: vw(8);
        margin-top: vw(8);
        padding: vw(8);
    
    
        .destination_bottom {
          display: flex;
          justify-content: space-between;
          color: #07080A;
          font-weight: 400;
          line-height: vw(22);
          height: vw(22);
          font-size: vw(14);

          >span {
            width: vw(400);

            &:nth-child(1){
              text-align: left;
            }
            &:nth-child(2){
              text-align: center;
            }
            &:nth-child(3){
              text-align: right;
            }
          }
        }
    
        .destination_center {
          font-weight: 700;
          margin-bottom: vw(4);
          font-size: vw(18);
          display: flex;
          color: #07080A;
          justify-content: space-between;
          align-items: center;
    
          >b {
            width: vw(386);
            height: vw(22);
            line-height: vw(22);
            font-weight: 700;
            word-break: break-word;

            &:last-child{
              text-align: right;
            }
          }

          >img {
            width: vw(80);
            height: vw(22);
            margin: 0 vw(8);
          }
        }
      }
    }
  }
}
.email-add{
  width: vw(444);
  height: vw(51);
  line-height: vw(51);
  font-size: vw(16);
  border-radius: vw(8);
  background-color: rgba(7, 19, 33, 0.08);
  // text-align: left;
  padding-left: vw(16);
  color: #07080A;
}
.new-repeat{
  display: flex;
  justify-content: space-between;
  div{
      width: vw(444);
      // margin-right: vw(16);
  }
}
.old-pass{
  width: vw(444);
}
.cancel-password{
  position: absolute;
  bottom: vw(16);
  left: vw(40);
  .cacel{
    width: vw(350);
    height: vw(61);
    line-height: vw(61);
    font-size: vw(24);
    color: rgba(13, 17, 35, 0.48);
    text-align: center;
    border: vw(2) solid rgba(0, 0, 0, 0.2);
    border-radius: vw(8);
    margin-right: vw(24);
    cursor: pointer;
    font-weight: 700;
  }
  .ant-form-item-control-input-content {
    display: flex;
  }
  
}
.cancel-perso{
  position: absolute;
  bottom: vw(16);
  left: vw(40);
}
