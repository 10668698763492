/*
  *公共样式
  bill_yang 2021.06
*/
@import '../style/index.scss';

// 字体引用
@font-face {
  font-family: 'myfontone';
  src: url('../FontWeb/Inter-Black.woff2');
}
@font-face {
  font-family: 'myfonttwo';
  src: url('../FontWeb/Inter-Bold.woff2');
}
@font-face {
  font-family: 'myfontthree';
  src: url('../FontWeb/Inter-Medium.woff2');
}
@font-face {
  font-family: 'myfontfour';
  src: url('../FontWeb/Inter-Regular.woff2');
}

@font-face {
  font-family: 'Genuine';
  src: url('../FontWeb/Genuine/Genuine.ttf');
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('../FontWeb/BEBAS-1.ttf');
}

@font-face {
  font-family: 'DIN-RegularItalic';
  src: url('../FontWeb/DIN-RegularItalic.otf');
}

html {
  font-size: 1px;
}

body {
  min-width: 1200px;

  /* Text Colors */
  --color-theme: #0063db;

  /* Background Colors */
  --bgck-light-blue: #17c7ff;
  --bgck-light-orange: #ff8e65;
  --bgck-light-green: #2dca73;
  --bgck-light-purple: #6f5bf8;
  --bgck-light-yellow: #f7c102;
  --bgck-light-red: #ff4e4b;
  --bgck-light-white: #ffffff;
  --bgck-airtaxi: #0063db;
  --bgck-light-gray: #dfe7f5;
  --bgck-modal: #30354f;
  --bg-theme: #07080a;
  --bg-results: #0d1123;
  --bg-flights: #1a2243;
  --bg-prompt: #0e142d;
  --bg-hover: #dfe7f5;
  --bg-map: rgba(13, 17, 35, 0.8);

  --fc-black: #07080a;
  --fw-normal: 400;
  --fw-bold: 700;

  --fs-300: 12px;
  --fs-400: 14px;
  --fs-500: 16px;
  --fs-600: 18px;
  --fs-700: 20px;
  --fs-800: 24px;
  --fs-900: 36px;
  --fs-1000: 48px;

  @media (max-width: 938px) {
    --fs-800: 20px;
    --fs-900: 28px;
    --fs-1000: 40px;
  }

  @media (max-width: 757px) {
    --fs-500: 12px;
    --fs-600: 12px;
    --fs-700: 14px;
    --fs-800: 16px;
    --fs-900: 18px;
    --fs-1000: 28px;
  }
}

@media screen and (max-width: 1500px) {
  html {
    font-size: 0.9px;
  }
}

@media screen and (max-width: 1380px) {
  html {
    font-size: 0.85px;
  }
}

@media screen and (max-width: 1290px) {
  html {
    font-size: 0.8px;
  }
}
// 缩放110%
@media all and (-moz-min-device-pixel-ratio: 1.09) and (-moz-max-device-pixel-ratio: 1.18),
  (-webkit-min-device-pixel-ratio: 1.09) and (-webkit-max-device-pixel-ratio: 1.18),
  (min-resolution: 1.09dppx) and (max-resolution: 1.18dppx) {
  html {
    font-size: 0.95px;
  }
}

// 缩放125%
@media all and (-moz-min-device-pixel-ratio: 1.19) and (-moz-max-device-pixel-ratio: 1.28),
  (-webkit-min-device-pixel-ratio: 1.19) and (-webkit-max-device-pixel-ratio: 1.28),
  (min-resolution: 1.19dppx) and (max-resolution: 1.28dppx) {
  html {
    font-size: 0.85px;
  }
}

// 缩放133% firefox
@media all and (-moz-min-device-pixel-ratio: 1.29) and (-moz-max-device-pixel-ratio: 1.4),
  (-webkit-min-device-pixel-ratio: 1.29) and (-webkit-max-device-pixel-ratio: 1.4),
  (min-resolution: 1.29dppx) and (max-resolution: 1.4dppx) {
  html {
    font-size: 0.8px;
  }
}

// 缩放150%
@media all and (-moz-min-device-pixel-ratio: 1.41) and (-moz-max-device-pixel-ratio: 1.6),
  (-webkit-min-device-pixel-ratio: 1.41) and (-webkit-max-device-pixel-ratio: 1.6),
  (min-resolution: 1.41dppx) and (max-resolution: 1.6dppx) {
  html {
    font-size: 0.75px;
  }
}

// 缩放175%
@media all and (-moz-min-device-pixel-ratio: 1.61) and (-moz-max-device-pixel-ratio: 1.8),
  (-webkit-min-device-pixel-ratio: 1.61) and (-webkit-max-device-pixel-ratio: 1.8),
  (min-resolution: 1.61dppx) and (max-resolution: 1.8dppx) {
  html {
    font-size: 0.65px;
  }
}

// 缩放200%
// @media all and (-moz-min-device-pixel-ratio: 1.81) and (-moz-max-device-pixel-ratio: 2.1),
//   (-webkit-min-device-pixel-ratio: 1.81) and (-webkit-max-device-pixel-ratio: 2.1),
//   (min-resolution: 1.81dppx) and (max-resolution: 2.1dppx) {
//   html {
//     font-size: 0.58px;
//   }
// }

.bg-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

* {
  // font-family: "Inter", -apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // letter-spacing: vw(1);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ccc;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}

p,
ol,
ul,
dl {
  margin: 0;
  list-style: none;
}

b {
  font-weight: 700;
}

.isShow {
  display: block;
}

.isNot {
  display: none !important;
}

img {
  width: 100%;
  display: block;
  border: none;
}

.max-width {
  width: vw(1300);
  height: 100%;
  margin: 0 auto;
  // overflow: hidden;
}

.swiper-width {
  width: vw(1380);
  height: 100%;
  margin: 0 auto;
}

.public-flex {
  display: flex;
  justify-content: space-between;
}

.public-algin-center {
  align-items: center;
}

.animation-in {
  animation-name: fadein;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-fill-mode: forwards;
}

.animation-left {
  animation-name: fadeleft;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-fill-mode: forwards;
}

.animation-right {
  animation-name: faderight;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-fill-mode: forwards;
}

// 从上至下动画效果
@keyframes fadein {
  0% {
    opacity: 0;
    transform: translate3d(0, vw(200), 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// 放大缩小动画效果
@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.laoding {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  // border-color: $bgck-light-red !important;
  border-color: none !important;
}
.ant-form-item-has-error .ant-input:focus {
  box-shadow: none !important;
}

.ant-input-password {
  .ant-input-password-icon {
    width: vw(24);
    height: vw(24);
    padding: 0;
    display: inline-block;

    svg {
      display: none;
    }

    &.anticon-eye {
      background-image: url(../imgs/svg/hidden_pw.svg);
    }

    &.anticon-eye-invisible {
      background-image: url(../imgs/svg/show_pw.svg);
    }
  }
}

// 下拉框
.ant-select-dropdown {
  border-radius: vw(8);
}

.ant-modal-content {
  box-shadow: none !important;
}

// 登录
.sign-container {
  background-color: $bg-map;
  color: #fff;
  border-radius: vw(16);
  overflow: hidden;
  width: vw(800) !important;
  padding: 0;

  .ant-modal-content {
    background-color: inherit;

    .anticon-close-circle {
      svg {
        width: vw(20);
        color: rgba(255, 255, 255, 0.2);
      }
    }

    .ant-modal-close {
      right: vw(30);
      top: vw(30);

      .anticon {
        color: rgba(196, 196, 196, 1);
        font-size: vw(40);
      }
    }

    .ant-modal-header {
      background-color: transparent;
      border: none;
      padding: vw(48) vw(30) 0 vw(30);

      .ant-modal-title {
        color: #fff;
        font-weight: 700;
        font-size: vw(36);
        line-height: normal;
        text-align: center;
      }
    }

    .ant-modal-body {
      padding: 0;
    }
  }
}

// 优惠卷list
.coupons-modal {
  padding: vw(30) 0 0 vw(120);
  height: 100%;

  .coupons-title {
    font-size: vw(24);
    // margin-bottom: vw(24);
  }

  .coupons-list {
    height: vw(476);
    // padding-bottom: vw(120);
    overflow-y: auto;
    // overflow: auto;

    .list-coupons-item {
      position: relative;
      border: vw(2) solid transparent;

      &.active {
        // border-color: #07080A;
        border: 2px solid #07080a;
        border-radius: 10.8738px;
      }

      > i {
        width: vw(24);
        height: vw(24);
        border-radius: vw(4);
        border: vw(2) solid rgba(255, 255, 255, 0.2);
        position: absolute;
        right: 80px;
        top: 50%;
        margin-top: vw(-12);
        cursor: pointer;

        &.active {
          border: none;
          background-image: url(../imgs/svg/selected.svg);
        }
      }
    }

    > .coupons-line {
      width: 100%;
      height: vw(2);
      background-image: url(../imgs/svg/line.svg);
      margin: vw(24) 0;
    }

    .dissatisfy-why {
      padding-left: vw(32);
      font-size: vw(12);
      position: relative;
      margin: vw(4) 0 vw(24) 0;
      color: #07080a;

      &::before {
        content: '';
        display: block;
        width: vw(24);
        height: vw(24);
        background-image: url(../imgs/svg/remind_error.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: vw(-12);
      }

      span {
        color: $bgck-light-red;
      }
    }
  }

  .coupons-list::-webkit-scrollbar {
    display: none;
  }

  .confirm-coupons {
    width: vw(400);
    height: vw(60);
    border-radius: vw(8);
    font-size: vw(24);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0 auto;
    background-image: url(../imgs/svg/inquire_bg.svg);
    position: absolute;
    bottom: vw(40);
    left: 0;
    right: 0;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 4px 16px rgb(72 84 115 / 40%);
    }
  }
}

// 优惠卷组件

.coupons-item {
  width: vw(443);
  height: vw(126);
  margin-top: vw(24);
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .coupons-left {
    width: vw(110);
    height: vw(94);
    margin-top: vw(16);
    margin-left: vw(24);
    display: flex;
    align-items: center;
    // justify-content: center;

    span {
      display: inline-block;
      width: vw(110);
      i {
        display: block;
        font-style: normal;
        text-align: center;

        &:nth-child(1) {
          font-size: vw(24);
          font-weight: 700;
          line-height: vw(29);
          color: #fff;
        }

        &:nth-child(2) {
          font-size: vw(12);
          text-align: center;
          color: #fff;

          > span {
            display: block;
          }
        }
      }

      b {
        display: flex;
        width: vw(110);
        height: vw(27);
        align-items: center;
        justify-content: center;
        font-size: vw(16);
        font-weight: 700;
        border-radius: vw(4);
        background-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        margin: vw(9) auto 0 auto;
      }
    }
  }

  .coupons-right {
    flex: 1;
    font-size: vw(12);
    padding: 0 vw(16) 0 vw(32);

    p {
      font-size: vw(16);
      padding-top: vw(16);
      font-weight: 700;
      height: vw(62);
      color: #fff;
    }

    > span {
      display: block;
      margin: vw(8) 0 vw(4) 0;
      white-space: nowrap;
      line-height: vw(15);
      color: #fff;
    }

    i {
      border-top: vw(2) solid rgba(255, 255, 255, 0.2);
      padding-top: vw(4);
      display: block;
      font-style: normal;
      line-height: vw(15);
      color: #fff;
      display: flex;
      div {
        display: block;
        width: vw(100);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        span {
          margin-right: vw(4);
        }
      }
    }
  }

  &.iscount {
    background-image: url(../imgs/svg/coupons_zk.svg);
  }

  &.disable-used-discount {
    background-image: url(../imgs/svg/coupons_zk_not.svg);
  }

  &.deduction {
    background-image: url(../imgs/svg/coupons_mj.svg);
  }

  &.disable-used-voucher {
    background-image: url(../imgs/svg/coupons_mj_not.svg);
  }

  &.iscount-used {
    background-image: url(../imgs/svg/coupons_zk_gq.svg);
    .coupons-left {
      i {
        color: rgba(0, 0, 0, 0.2) !important;
      }
    }
    .coupons-right {
      p,
      span,
      i {
        color: rgba(0, 0, 0, 0.2);
      }
      i {
        border-top: vw(2) solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.dissatisfy-zk {
    color: rgba(0, 0, 0, 0.2);
    background-image: url(../imgs/svg/coupons_zk_gq.svg);

    .coupons-left {
      i {
        color: rgba(0, 0, 0, 0.2) !important;
      }
    }
    .coupons-right {
      p,
      span,
      i {
        color: rgba(0, 0, 0, 0.2);
      }
      i {
        border-top: vw(2) solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.dissatisfy-mj {
    color: rgba(0, 0, 0, 0.2);
    background-image: url(../imgs/svg/coupons_mj_gq.svg);

    .coupons-left {
      i {
        color: rgba(0, 0, 0, 0.2) !important;
      }
    }
    .coupons-right {
      p,
      span,
      i {
        color: rgba(0, 0, 0, 0.2);
      }
      i {
        border-top: vw(2) solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.deduction-used {
    background-image: url(../imgs/svg/coupons_mj_gq.svg);
    color: rgba(0, 0, 0, 0.2);

    .coupons-left {
      i {
        color: rgba(0, 0, 0, 0.2) !important;
      }
    }
    .coupons-right {
      p,
      span,
      i {
        color: rgba(0, 0, 0, 0.2);
      }
      i {
        border-top: vw(2) solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.notUse {
    color: rgba(0, 0, 0, 0.2);
    background-image: url(../imgs/svg/coupons_mj_gq.svg);

    .coupons-left {
      i {
        color: rgba(0, 0, 0, 0.2) !important;
      }
    }
    .coupons-right {
      p,
      span,
      i {
        color: rgba(0, 0, 0, 0.2);
      }
      i {
        border-top: vw(2) solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .not-coupons {
    width: vw(126);
    height: vw(126);
    position: absolute;
    right: vw(100);
    top: vw(0);
    // transform: rotate(30deg);

    &.used {
      background-image: url(../../assets/imgs/svg/used.svg);
    }

    &.over {
      background-image: url(../../assets/imgs/svg/explred.svg);
    }
  }
}

.page-404 {
  // height: 100vh;
  // display: flex;
  // flex-direction:column;
  // align-items: center;
  // justify-content: center;
  width: vw(238);
  // height: vw(214);
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%,-50%);
  margin: vw(260) auto 0;

  img {
    width: vw(238);
    height: vw(168);
    margin-bottom: vw(24);
  }
  p {
    font-style: normal;
    font-weight: 700;
    font-size: vw(18);
    line-height: vw(22);
    color: rgba(0, 0, 0, 0.2);
    margin-left: vw(4);
  }
  .home-btn {
    width: vw(210);
    height: vw(40);
    margin: vw(24) auto 0;
    border-radius: vw(8);
    color: #fff;
    line-height: vw(38);
    font-size: vw(18);
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    background-image: url('../imgs/svg/user-sub.svg');
    &:hover {
      box-shadow: 0px vw(4) vw(32) rgba(51, 94, 126, 0.37);
    }
  }
}

// 空状态
.no-data {
  height: 100%;
  width: 100%;
  position: relative;

  &.active-top {
    .no-box {
      margin-top: vw(120);

      // .no-content {
      //   margin-left: vw(-40);
      // }
    }
  }

  .no-box {
    height: 100%;
    width: 100%;
    padding-right: vw(40);
    display: inline-flex;
    justify-content: center;
    // align-items: center;
    margin-top: vw(120);

    img {
      width: vw(240);
      // height: vw(170);
      margin: 0 auto 0 auto;
    }

    p {
      color: rgba(0, 0, 0, 0.2);
      font-size: vw(24);
      font-weight: 700;
      text-align: center;
      margin: vw(24) 0 vw(72) 0;
    }
  }
}

// 日历
.ant-picker-dropdown {
  width: vw(350);
  border-radius: vw(8);
  overflow: hidden;
  box-shadow: 0px vw(4) vw(56) rgba(77, 100, 126, 0.25);

  .ant-picker-panel,
  .ant-picker-year-panel,
  .ant-picker-month-panel,
  .ant-picker-decade-panel,
  .ant-picker-date-panel {
    width: 100%;
  }

  .ant-picker-body {
    padding: vw(16);
  }

  .ant-picker-content {
    width: 100%;

    th {
      font-size: vw(12);
      width: auto;
      color: $bgck-light-green;
    }

    // 默认
    .ant-picker-cell {
      &:hover .ant-picker-cell-inner {
        background-color: transparent !important;
        color: $bgck-airtaxi;

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          border: vw(1) solid $bgck-airtaxi;
          border-radius: vw(2);
          border-radius: 50%;
          content: '';
        }
      }

      .ant-picker-cell-inner {
        font-size: vw(12);
        font-weight: 500;
        min-width: vw(32);
        height: vw(32);
        line-height: vw(32);
      }
    }

    .ant-picker-cell-in-view {
      .ant-picker-cell-inner {
        // color: #0063DB;
        color: #07080a;
      }
    }

    // 今天
    .ant-picker-cell-today {
      .ant-picker-cell-inner {
        // color: $bgck-airtaxi;

        &::before {
          display: none;
        }
      }
    }

    // 不能选择
    .ant-picker-cell-disabled {
      background-color: #fff;

      &::before {
        display: none;
      }

      .ant-picker-cell-inner {
        font-size: vw(12);
        color: rgba(0, 0, 0, 0.2);
        background-color: transparent;
      }
    }

    // 选中
    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        color: #fff;
        background-color: $bgck-airtaxi !important;
        border-radius: 50%;
        font-weight: 700;
      }

      &:hover .ant-picker-cell-inner {
        background-color: $bgck-airtaxi !important;
        color: #fff;
      }
    }
  }

  .ant-picker-header {
    display: flex;
    align-items: center;
    padding: vw(24) vw(16) 0 vw(16);
    border: none;

    .ant-picker-header-prev-btn {
      width: 24px;
      height: 24px;
      background-image: url(../imgs/svg/left.svg);
      background-repeat: no-repeat;
      background-size: cover;

      .ant-picker-prev-icon {
        display: none;
      }
    }

    .ant-picker-header-view {
      font-weight: 700;
      color: #000;
      line-height: normal;

      button {
        line-height: normal;
      }
    }

    .ant-picker-header-next-btn {
      width: 24px;
      height: 24px;
      background-image: url(../imgs/svg/left.svg);
      background-repeat: no-repeat;
      background-size: cover;
      transform: rotate(180deg);

      .ant-picker-next-icon {
        display: none;
      }
    }
  }

  .ant-picker-footer,
  .ant-picker-header-super-next-btn,
  .ant-picker-header-super-prev-btn {
    display: none;
  }
}

// 支付加载
.loading-pay {
  width: 100%;
  height: 100vh;
  background-color: $bg-results;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    position: absolute;
    top: 50%;
    margin-top: vw(-160);
    font-size: vw(24);
    font-weight: 700;
    color: $bgck-light-red;

    p {
      text-align: center;
      margin: vw(4) 0;
    }
  }
}

// 优惠卷弹框
.coupons-box {
  width: 800px !important;
  height: vw(785);
  padding-bottom: 0;

  .ant-modal-content,
  .ant-modal-body {
    height: 100%;
  }
}

// 底部政策
.clause {
  border-top: vw(1) solid rgba(0, 0, 0, 0.2);
  font-size: vw(18);
  padding: vw(40) 0 vw(56);
  overflow: hidden;
  color: #07080a;
  font-weight: 500;

  .refund {
    font-size: vw(18);
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: vw(24);
    border: none;
    background-color: transparent;
  }

  .refund-cause {
    background-color: #eff6fa;
    padding: vw(16) 0;
    border-radius: vw(16);
    margin-bottom: vw(24);
    > span {
      margin: 0;
    }
    .reason {
      height: vw(26);
      line-height: vw(26);
      font-weight: 700;
      margin-left: vw(16);
    }
    textarea {
      width: vw(1268);
      height: vw(120);
      border-radius: vw(16);
      background-color: #fff;
      margin: vw(8) vw(16) vw(16);
      border: none;
      outline: none;
      font-size: vw(18);
      color: #07080a;
      resize: none;
      padding: vw(16);
    }

    ul {
      display: flex;
      margin-left: vw(16);

      li {
        width: vw(160);
        height: vw(38);
        border-radius: vw(8);
        font-weight: 700;
        font-size: vw(18);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 2px solid rgba(0, 0, 0, 0.2);

        &:nth-child(2) {
          margin-left: vw(16);
          color: #fff;
          background: linear-gradient(94.57deg, #0063db 0%, #17c7ff 100%);
          border: transparent;
          &:hover {
            box-shadow: 0px 4px 16px rgba(72, 84, 115, 0.4);
          }
        }
        > button {
          width: 100%;
          height: 100%;
          border: none;
          background-color: transparent;
          cursor: pointer;
        }
      }
    }
  }

  .refund-detail {
    background-color: #eff6fa;
    border-radius: vw(16);
    ul {
      // padding-bottom: vw(24);
      padding: vw(16);
      position: relative;
      margin-bottom: vw(24);

      &::before {
        content: '';
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        width: 100vw;
        position: absolute;
        left: vw(-310);
        bottom: 0;
      }

      li {
        display: flex;
        // align-items: center;

        & + li {
          margin-top: vw(16);
        }

        span {
          margin: 0;
          color: #07080a;
          white-space: nowrap;
          opacity: 0.5;
          // margin-top: vw(4);
        }

        p {
          color: #07080a;
          font-size: vw(18);
          font-weight: 700;
          flex: 1;
        }
      }
    }
  }

  span {
    display: block;
    color: #07080a;
    font-weight: 500;
    margin-bottom: vw(4);

    &:nth-of-type(2) {
      margin-top: vw(24);
    }
  }

  p {
    // margin-top: vw(4);
    color: rgba(7, 8, 10, 0.5);
    font-size: vw(14);

    i {
      font-style: normal;
      color: #07080a;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 700;
    }

    b {
      font-weight: normal;
    }
  }
}

.country-code::-webkit-scrollbar {
  display: none;
}

.ant-form-item-control-input-content {
  position: relative;
}

// 选择区号国家
.two-item {
  position: relative;
}

.country-code-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}

.anticon-plus {
  color: #fff;
}

.country-code {
  background-color: #fff;
  box-shadow: 0px 2.17969px 30.5156px rgba(77, 100, 126, 0.25);
  border-radius: vw(8);
  width: vw(444);
  height: vw(292);
  padding: vw(16) vw(0) vw(16) vw(16);
  position: absolute;
  left: 0;
  top: vw(58);
  z-index: 1002;
  overflow: hidden;

  // &::before {
  //   content: '';
  //   display: block;
  //   width: vw(4);
  //   height: 60%;
  //   border-radius: vw(4);
  //   background-color: rgba(0, 0, 0, 0.2);
  //   position: absolute;
  //   right: vw(8);
  //   bottom: 10%;
  // }

  // .ant-input-affix-wrapper {
  //   border: none !important;
  //   height: vw(40) !important;

  //   .ant-input-prefix {
  //     margin: 0 !important;
  //   }

  //   .anticon-search {
  //     color: rgba(0, 0, 0, 0.6) !important;
  //   }

  //   input {
  //     color: #000 !important;
  //     font-size: vw(14) !important;
  //   }
  // }
  .ant-input {
    width: vw(406);
    border: none;
    height: vw(39);
    border-bottom: 2px solid rgba(7, 19, 33, 0.08);
    padding: 0 0 vw(8) 0;
    &:focus {
      border-color: none;
      box-shadow: none;
    }
  }

  // ul::-webkit-scrollbar {
  //   display: none
  // }

  ul {
    height: vw(230);
    position: relative;
    overflow-y: auto;
    padding-right: vw(16);

    li {
      height: vw(39);
      line-height: vw(28);
      font-size: vw(12);
      color: #07080a;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: vw(8);
      padding-bottom: vw(8);
      border-bottom: vw(1) solid rgba(7, 19, 33, 0.08);
      cursor: pointer;
      white-space: nowrap;
      // &:nth-child(1){
      //   border-top: 2px solid rgba(7, 19, 33, 0.08);
      //   margin-top: vw(0);
      //   padding-top:vw(8) ;

      // }
      > div {
        display: flex;
        align-items: center;
      }
      img {
        width: vw(20);
        height: vw(13);
      }
      .countryname {
        margin-left: vw(8);
      }

      &:hover {
        color: $bgck-airtaxi;
      }
    }
  }
}

// antd—btn
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-right-width: vw(2) !important;
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  // border-right-width: vw(2) !important;
  border-color: #07080a !important;
}

// 隐藏input number箭头
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

// 提示框样式
.ant-message {
  top: vw(80);

  .ant-message-notice-content {
    border-radius: vw(8);
    padding: 0;
    overflow: hidden;
    color: #a49fc4;
    box-shadow: none;

    .ant-message-custom-content {
      padding: vw(16) vw(32);
      display: flex;
      align-items: center;
      // background-color: rgba(26, 34, 67, 0.8);
    }

    .anticon {
      width: vw(40);
      height: vw(40);
      background-repeat: no-repeat;
      background-size: cover;

      svg {
        display: none;
      }
    }

    .ant-message-warning {
      background: linear-gradient(90deg, rgba(247, 193, 2, 0.3) 0%, rgba(247, 193, 2, 0) 50%),
        rgba(26, 34, 67, 1);

      .anticon {
        background-image: url(../imgs/svg/warning.svg);
      }

      span {
        color: $bgck-light-yellow;
      }
    }

    .ant-message-success {
      background: linear-gradient(90deg, rgba(45, 202, 115, 0.3) 0%, rgba(45, 202, 115, 0) 50%),
        rgba(26, 34, 67, 1);

      .anticon {
        background-image: url(../imgs/svg/success.svg);
      }

      span {
        color: $bgck-light-green;
      }
    }

    .ant-message-error {
      background: linear-gradient(90deg, rgba(255, 78, 75, 0.3) 0%, rgba(255, 78, 75, 0) 50%),
        rgba(26, 34, 67, 1);

      .anticon {
        background-image: url(../imgs/svg/error.svg);
      }

      span {
        color: $bgck-light-red;
      }
    }
  }
}
.ant-modal:not(.sign-container) {
  .ant-modal-content {
    background: #ffffff;

    .ant-modal-body {
      padding: vw(16);
    }

    .anticon-exclamation-circle {
      width: vw(16);
      height: vw(16);
      border-radius: 50%;
    }

    .ant-modal-confirm-content {
      font-weight: 400;
      font-size: vw(18);
      line-height: vw(26);
      color: #07080a;
    }

    .ant-modal-confirm-btns {
      .ant-btn {
        width: vw(80);
        height: vw(38);
        border-radius: vw(8);
        background-color: rgba(7, 19, 33, 0.08);
        border: none;
        color: #07080a;
        padding: 0;
        font-weight: 700;
        font-size: vw(18);

        & + .ant-btn {
          border: none;
          margin-left: vw(16);
          background: rgba(7, 19, 33, 0.08);
        }

        &:hover {
          background: rgba(7, 19, 33, 0.15);
        }
      }
    }
  }
}

.ant-modal {
  .ant-modal-content {
    background: rgba(26, 34, 67, 0.85);
    border-radius: vw(8);

    .ant-modal-confirm-content {
      color: #a49fc4;
    }

    .ant-modal-confirm-body {
      display: flex;
      align-items: center;

      .ant-modal-confirm-content {
        margin: 0;
        flex: 1;
      }
    }

    .anticon-exclamation-circle {
      width: vw(40);
      height: vw(40);
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../imgs/svg/warning.svg);

      svg {
        display: none;
      }
    }

    .ant-modal-confirm-btns {
      .ant-btn {
        width: vw(80);
        height: vw(32);
        border-radius: vw(8);
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.2);
        color: #fff;
        padding: 0;

        & + .ant-btn {
          border: none;
          background: linear-gradient(94.57deg, #0063db 0%, #17c7ff 100%);
          margin-left: vw(16);
        }
      }
    }
  }
}
// 面包屑
.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > li {
    &:not(:first-child)::before {
      content: '';
      width: vw(18);
      height: vw(12);
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-image: url('../imgs/svg/breadcrumb_disabled.svg');
      margin: 0 vw(24);
    }

    &.actived {
      &:not(:first-child)::before {
        background-image: url('../imgs/svg/breadcrumb_actived.svg');
      }

      > span {
        color: #07080a;
      }
    }

    &.currentActived {
      > span {
        font-weight: 700;
      }
    }

    > span {
      font-weight: 400;
      font-size: vw(18);
      line-height: vw(22);
      color: rgba(7, 8, 10, 0.5);
    }
  }
}

@media (min-height: 753px) and (max-height: 796px) {
  html {
    font-size: 0.9px;
  }
}

@media (min-height: 706px) and (max-height: 752px) {
  html {
    font-size: 0.85px;
  }
}

@media (min-height: 663px) and (max-height: 705px) {
  html {
    font-size: 0.8px;
  }
}
@media (min-height: 624px) and (max-height: 662px) {
  html {
    font-size: 0.75px;
  }
}

@media (max-height: 623px) {
  html {
    font-size: 0.7px;
  }
}
.sel-err {
  width: 100%;
  height: vw(116);
  border: 1.6px solid #ff4e4b;
  box-shadow: 0px 2.17969px 30.5156px rgba(77, 100, 126, 0.25);
  border-radius: 8px;
  position: absolute;
  top: vw(84);
  left: 0;
  background-color: #fff;
  padding: vw(8) vw(16);
  z-index: 100;
  img {
    width: vw(24);
    height: vw(24);
    margin-right: vw(8);
  }
  > div {
    color: #ff4e4b;
    font-size: vw(14);
    height: vw(24);
    line-height: vw(24);
    margin-bottom: vw(4);
    display: flex;
  }
  p {
    font-size: vw(12);
    height: vw(24);
    line-height: vw(24);
    display: flex;
    margin-left: vw(24);
    img {
      margin-right: vw(8);
    }
  }
}
.sel-rec {
  border-color: #2dca73;
}
.err {
  color: #2dca73 !important;
}

// 底部cookie
.home_privacy_policy {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: vw(16) vw(0);
  background: #0063db;
  font-weight: 700;
  font-size: vw(14);
  line-height: vw(17);
  color: #fff;
  z-index: 99999;
  text-align: center;

  .text_prompt {
    a {
      color: #fff;
      cursor: pointer;
      border-bottom: vw(1.6) solid #fff;
    }
  }

  .accept_btn {
    display: inline-block;
    font-size: vw(14);
    padding: vw(8) vw(16);
    border: vw(1.6) solid #fff;
    border-radius: vw(66);
    cursor: pointer;
    margin-left: vw(40);

    &:hover {
      background: #fff;
      border-color: transparent;
      color: #0063db;
    }
  }
}
