/*
  *优惠卷弹窗
*/
@import '../index.scss';

.ant-modal-mask {
  backdrop-filter: blur(0px) !important;
  background: rgba(0, 0, 0, 0.64) !important;
}

.successful-coupons {
  .coupons-wrapper {
    display: flex;
    justify-content: center;
    padding-top: vw(40);
    border-radius: vw(16);
    background-image: url(../../imgs/webp/coupons-popup-bg.webp);

    .successful-text {
      width: vw(456);

      > h3 {
        font-weight: 700;
        font-size: vw(32);
        line-height: vw(32);
        text-align: center;
        color: #07080a;
        margin-bottom: vw(24);
      }

      > p {
        font-weight: 400;
        width: vw(376);
        font-size: vw(16);
        line-height: vw(24);
        text-align: center;
        color: #07080a;
        opacity: 0.5;
        margin: 0 auto;
      }

      .form {
        width: vw(376);
        margin: vw(16) auto vw(-16);
        .name {
          margin-bottom: vw(16);
          position: relative;
          .error-text {
            color: #ff4e4b;
            font-size: vw(12);
            position: absolute;
          }
        }
        .password {
          position: relative;
        }
        .icon-img {
          width: vw(24);
          height: vw(24);
          position: absolute;
          top: vw(16);
          left: vw(24);
        }
        .icon-pw {
          cursor: pointer;
          width: vw(24);
          height: vw(24);
          position: absolute;
          top: vw(16);
          right: vw(24);
        }
        input {
          width: vw(376);
          height: vw(56);
          outline: none;
          border: 1.6px solid rgba(0, 0, 0, 0.2);
          border-radius: vw(8);
          padding: 0 vw(64);
          &:hover,
          &:focus {
            border-color: #07080a;
          }
          &::placeholder {
            color: rgba(0, 0, 0, 0.2);
          }
          &.error {
            border-color: #ff4e4b;
          }
        }
      }
    }
  }
}

.activity-popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
  &.foot-register {
    background-color: rgba(0, 0, 0, 0.64);
  }

  .activity-content {
    width: vw(456);
    border-radius: vw(16);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .activity-top {
      padding: vw(36) vw(8);
      width: 100%;
      background: url(../../imgs/webp/activity-bg.webp) no-repeat center center/cover;
      display: grid;
      place-items: center;
      gap: vw(8);
      text-align: center;
      color: #fff;

      .title {
        font-size: vw(32);
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
      .text {
        width: vw(408);
        font-size: vw(16);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    }

    .activity-close {
      width: 24px;
      height: 24px;
      background-image: url(../../imgs/svg/coupons_close.svg);
      position: absolute;
      right: vw(16);
      top: vw(16);
      cursor: pointer;
      z-index: 2;
    }

    .coupons-wrapper {
      width: 100%;
      position: relative;
      display: grid;
      place-items: center;
      background-color: #fff;
      border-radius: 0 0 vw(16) vw(16);
      &.bg-image {
        border-radius: vw(16);
      }

      .coupons-content {
        position: relative;
        height: vw(240);
        background: rgba(7, 19, 33, 0.08);
        padding: vw(39) vw(40) vw(40);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3,
        .text {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        h3 {
          font-weight: 700;
          font-size: vw(32);
          line-height: vw(48);
          text-align: center;
          color: #07080a;
          margin-bottom: vw(16);
        }

        .text {
          font-weight: 400;
          font-size: vw(16);
          line-height: vw(24);
          text-align: center;
        }

        .successful_text {
          span {
            font-size: vw(40);
            line-height: vw(52);
          }
        }

        p {
          font-weight: 400;
          font-size: vw(16);
          line-height: vw(19);
          text-align: center;
          margin-top: vw(24);
        }
      }

      .signin {
        padding: vw(40) vw(40);

        > form {
          .ant-form-item {
            margin-bottom: vw(16);

            &:nth-child(3),
            &:last-child {
              margin-bottom: vw(16);
            }

            .ant-form-item-explain-error {
              font-size: vw(12);
            }

            &:nth-child(1),
            &:nth-child(2) {
              .ant-form-item-explain-error {
                position: absolute;
                line-height: vw(15);
              }
            }
          }
        }

        .ant-form-item {
          &:not(.ant-form-item-has-error) {
            .ant-input-affix-wrapper {
              &:hover,
              &:focus {
                border-color: rgba(0, 0, 0, 0.2) !important;
              }
            }
          }
        }

        .ant-form-item-has-error {
          .ant-input-affix-wrapper {
            border-color: $bgck-light-red !important;
          }
          .ant-form-item-explain-error {
            min-height: auto;
          }
        }

        .ant-input-affix-wrapper {
          width: 100%;
          height: vw(48);
          border: vw(2) solid rgba(0, 0, 0, 0.2);
          border-radius: vw(8);
          padding: vw(4) vw(16) vw(4) vw(24);
          box-shadow: none;

          .ant-input {
            color: #07080a;

            &::placeholder {
              font-weight: 400;
              font-size: vw(18);
              line-height: vw(22);
              color: rgba(7, 19, 33, 0.2);
            }
          }
        }

        .check-terms {
          margin-bottom: vw(16);
          display: flex;
          align-items: center;

          &:nth-last-of-type(2) {
            margin-bottom: vw(40);
          }

          i {
            display: block;
            width: vw(24);
            height: vw(24);
            border-radius: vw(4);
            border: vw(2) solid rgba(0, 0, 0, 0.2);
            background-color: transparent;
            cursor: pointer;
            flex-shrink: 0;

            &.active {
              border-radius: vw(4);
              background-image: url(../../imgs/svg/selected.svg);
              border: none;
            }
          }

          p {
            font-size: vw(14);
            color: #07080a;
            margin-left: vw(16);
            text-align: left;

            a {
              font-weight: 700;
              color: #07080a;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        .ant-btn {
          display: block;
          width: 100%;
          height: vw(53);
          border-radius: vw(8);
          font-size: vw(24);
          font-weight: 700;
          color: #fff;
          outline: none;
          background: #0063d8;
          padding: 0;

          &:hover {
            opacity: 0.8;
          }
        }

        .link-login {
          font-weight: 400;
          font-size: vw(14);
          line-height: vw(17);
          text-align: left;
          color: #07080a;

          > button {
            font-weight: 700;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .or {
          font-size: vw(24);
          line-height: vw(29);
          color: rgba(7, 8, 10, 0.8);
          padding: vw(40) 0 vw(24) 0;
          text-align: center;
          position: relative;

          &::before,
          &::after {
            content: '';
            display: block;
            width: vw(145.5);
            height: vw(1);
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: vw(54.5);
          }

          &::before {
            left: vw(0);
          }

          &::after {
            right: vw(0);
          }
        }

        .other-login {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            width: vw(40);
            height: vw(40);
            cursor: pointer;
            position: relative;

            &:hover {
              opacity: 0.72;
            }

            button {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              opacity: 0;
              cursor: pointer;
            }

            i {
              display: block;
              width: vw(40);
              height: vw(40);

              &.google {
                background-image: url(../../imgs/svg/login-google.svg);
              }

              &.facebook {
                background-image: url(../../imgs/svg/login-facebook.svg);
              }
            }

            span {
              color: #fff;
              font-size: 0;

              + span {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                margin-left: 0;
              }
            }

            .anticon {
              color: rgba(255, 255, 255, 0.8);
            }

            & + p {
              margin-left: vw(40);
            }
          }
        }

        .ant-input-password-icon {
          &.anticon-eye {
            background-image: url(../../imgs/svg/login_show_pw.svg);
          }

          &.anticon-eye-invisible {
            background-image: url(../../imgs/svg/login_hidden_pw.svg);
          }
        }
        .verify-password {
          top: vw(51);
        }
      }
    }
  }
}

.submit_btn_suc {
  width: vw(210);
  height: vw(53);
  line-height: vw(53);
  margin-left: vw(123);
  margin-bottom: vw(40);
  border-radius: vw(8);
  border: none;
  outline: none;
  font-weight: 700;
  font-size: vw(24);
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-image: url('../../imgs/svg/user-sub.svg');

  &:hover {
    box-shadow: 0px vw(4) vw(32) rgba(51, 94, 126, 0.37);
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
  }
}
.select {
  position: relative;
  width: vw(376);
  margin: 0 auto;

  .dropDownMenu {
    margin: vw(40) 0 vw(75) 0;
  }

  &.error {
    .choose_name {
      border-color: #ff4e4b;
    }

    .error_text {
      color: #ff4e4b;
      font-size: vw(12);
      position: absolute;
      top: vw(100);
    }
  }
}
