@import './index.scss';

body {
  min-width: unset !important;
}

.header-scroll {
  .header-container {
    background: white;
  }

  .header-nav {
    > li {
      &.header-nav-parent {
        > span {
          > a {
            color: black;
          }
        }
      }

      > a {
        color: black;
      }
    }

    .header-nav-children {
      background: #FFFFFF;
      > li {
        a {
          color: #07080A;
        }
      }
    }

  }

  .portrait-name {
    border-color: black;
    .user-name {
      color: black;
    }
  }

  .sign {
    border-color: black !important;
    color: black !important;

    &:hover {
        color: #FFFFFF !important;
      border: transparent !important;
    }
  }
}

.layout .header-nav > li > a,
.layout .header-nav > li > span > a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.layout .header-nav > li > span {
  display: flex;
  align-items: center;
}

.layout {
  // header-nav
  .header-nav {
    padding: 0 16px;

    > li {
      margin-right: vw(24);
    }
  }
}

// footer
.footer {
  background-color: #000000;
  color: #fff;
  font-size: vw(18);
  padding: vw(56) 16px vw(32) 16px;

  background-image: url('../imgs/webp/footer.webp');

  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;

  @media (max-width: 768px) {
    padding: 10.6666666667vw 13.8666666667vw;
    background-image: url('../imgs/webp/newFooter.png');
    background-position: 50%;
  }

  .max-width {
    width: 100%;
    max-width: 1300rem;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: vw(1);
      background-color: #fff;
      margin: vw(32) 0;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;

      & > * {
        display: flex;
        flex-direction: column;
        &:nth-child(2) {
          flex-direction: row;
        }
      }
    }
  }

  .legal {
    width: 100%;
    max-width: 1300rem;
    font-size: vw(14);
    margin: 0 auto;
    .info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: vw(16);
      margin-top: vw(24);
    }
  }

  ul {
    list-style: none;

    & > li {
      flex: 1;

      &:nth-child(3) {
        img {
          width: vw(178);
        }
        .logo-nbaa {
          margin-top: vw(51);
          margin-bottom: vw(24);
        }
        p {
          cursor: auto;
        }
      }

      span {
        color: rgba(255, 255, 255, 0.5);

        font-size: vw(14);
        line-height: vw(17);
      }

      a {
        display: block;
      }

      p,
      a {
        margin: vw(24) 0 0 0;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.85);
        line-height: vw(22);

        i {
          font-style: normal;
          position: relative;
        }

        &:hover {
          i {
            color: #fff;
            font-weight: 700;

            &::before {
              content: '';
              width: vw(24);
              height: vw(24);
              background-image: url('../imgs/svg/down_j.svg');
              background-repeat: no-repeat;
              background-size: contain;
              position: absolute;
              transform: rotate(-90deg);
              right: vw(-32);
              top: 50%;
              margin-top: vw(-12);
            }
          }
        }
      }

      @media (max-width: 768px) {
        margin: 1em 0;

        span {
          font-size: vw(22);
        }

        p,
        a {
          i {
            font-size: vw(24);
          }

          &:hover {
            i {
              color: #fff;
              font-weight: 700;
            }
          }
        }

        .footer-logo {
          margin: 0;
          width: 30.1333333333vw;
        }

        .copyright,
        .logo-text {
          text-align: left;
          color: hsla(0, 0%, 100%, 0.5);
          font-size: 2.4vw;
        }
      }
    }
  }

  .social {
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
      margin-top: vw(72);
      position: relative;

      // &::before {
      //   content: '';
      //   display: block;
      //   width: 100%;
      //   height: vw(1);
      //   background-color: #fff;
      //   position: absolute;
      //   bottom: vw(72);
      // }
    }

    @media (max-width: 768px) {
      position: absolute;
      top: -2em;
      left: 0;

      background-color: #1a2243;
      width: 100%;
    }

    .footer-logo {
      width: vw(144) !important;
      // height: vw(50);
    }

    .logo-text {
      font-size: vw(12);
      text-align: center;
      white-space: nowrap;
      font-size: vw(12);
      margin: 0 vw(40);
      color: rgba(255, 255, 255, 0.5);
    }

    .copyright {
      text-align: center;
      font-size: vw(12);
      white-space: nowrap;
      color: rgba(255, 255, 255, 0.5);
    }

    ul {
      display: flex;
      li {
        width: vw(24);
        height: vw(24);
        cursor: pointer;
        flex: none;
        margin-right: vw(40);

        &.icon-facebook {
          background: url('../imgs/svg/icon_Facebook.svg') no-repeat center center/vw(24) vw(24);
        }

        &.icon-twitter {
          background: url('../imgs/svg/icon_Twitter.svg') no-repeat center center/vw(24) vw(24);
        }

        &.icon-instagram {
          background: url('../imgs/svg/icon_Instagram.svg') no-repeat center center/vw(24) vw(24);
        }

        &.icon-linkedin {
          background: url('../imgs/svg/icon_LinkedIn_.svg') no-repeat center center/vw(24) vw(24);
          margin: 0;
        }

        &:hover {
          content: '';

          padding: vw(8);
          border-radius: vw(8);
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
.sign-up {
  width: vw(520);
  height: vw(272);
  background: rgba(255, 255, 255, 0.08);
  padding: vw(16) vw(24);
  .title {
    font-size: vw(24);
    font-weight: 700;
    line-height: vw(29);
    color: #17c7ff;
    margin-bottom: vw(13);
  }
  .text {
    font-size: vw(18);
    line-height: vw(22);
  }
  .email-input {
    margin-top: vw(40);
    margin-bottom: vw(16);
    input {
      width: vw(326);
      height: vw(46);
      outline: none;
      border: none;
      border-radius: vw(8);
      margin-right: vw(8);
      color: #07080a;
      padding-left: vw(16);
    }
    button {
      width: vw(138);
      height: vw(46);
      border-radius: vw(8);
      background-color: #0063db;
      border: none;
      color: fff;
      font-size: vw(18);
      font-weight: 700;
      line-height: vw(22);
      cursor: pointer;
    }
    .error-text {
      font-size: vw(14);
      color: #ff4e4b;
      position: absolute;
    }
  }
  .agreement {
    display: flex;
    height: vw(52);
    align-items: center;
    div {
      width: vw(432);
      font-size: vw(14);
      color: #fff;
      a {
        display: inline-block;
        margin: 0;
        font-weight: 700;
        text-decoration: underline;
        color: #fff !important;
      }
    }
  }
  i {
    display: block;
    width: vw(24);
    height: vw(24);
    border-radius: vw(4);
    border: vw(2) solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
    cursor: pointer;
    margin-right: vw(16);
    &.active {
      border-radius: vw(4);
      border: none;
      background-image: url(../imgs/svg/selected.svg);
    }
  }
}
