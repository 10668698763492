/*
  *公共样式变量
  bill_yang 2021.06
*/
@use 'sass:math';
// 字体大小
$font-size: (
  small: 12px,
  medium: 14px,
  large: 16px,
  xlarge: 18px,
  xxlarge: 20px,
);

// 文字颜色
$color_theme: #0063db;

// 背景颜色
$bgck-light-blue: #17c7ff;
$bgck-light-orange: #ff8e65;
$bgck-light-green: #2dca73;
$bgck-light-purple: #6f5bf8;
$bgck-light-yellow: #f7c102;
$bgck-light-red: #ff4e4b;
$bgck-light-white: #ffffff;
$bgck-airtaxi: #0063db;
$bgck-light-gray: #dfe7f5;
$bgck-modal: #30354f;
$bg-theme: #07080a;
$bg-results: #0d1123;
$bg-flights: #1a2243;
$bg-prompt: #0e142d;
$bg-hover: #dfe7f5;
$bg-map: rgba(13, 17, 35, 0.8);

//vw适配方法
@function vw($px) {
  // @return math.div($px, 1920) * 100vw;
  @return $px * 1rem;
}

@mixin flex($justify: flex-start, $align: stretch) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

// Responsive Media Query Mixin
@mixin media-header($min, $max, $width) {
  @media (min-width: $min) and (max-width: $max) {
    .layout .header {
      .max-width {
        width: vw($width);
      }
    }
  }
}

// Media Queries
@include media-header(0px, 670px, 500);
@include media-header(671px, 1000px, 1024);
@include media-header(1001px, 1394px, 1280);
@include media-header(1395px, 1520px, 1300);
@include media-header(1521px, 1650px, 1400);

@media screen and (max-width: 670px) {
  .layout .header {
    .max-width {
      width: vw(500);
    }
  }
}
